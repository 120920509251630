import React from "react"
import MainLayout from "../components/layout/main"
import "../styles/main.scss"

import SEO from "../components/seo"

class Contacts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      message_sent: false,
    }
  }

  render() {
    return <MainLayout title="Cette page est introuvable"
                       description="La page à laquelle vous tentez d’accéder n’existe pas ou a été supprimée. Vérifier l’adresse que vous avez saisie ou revenez simplement sur notre page d’accueil."
                       theme="light">
      <SEO title="Cette page est introuvable" description={"La page à laquelle vous tentez d’accéder n’existe pas ou a été supprimée. Vérifier l’adresse que vous avez saisie ou revenez simplement sur notre page d’accueil."} />
      <div className="slice">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">

            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  }
}


export default Contacts
